.button {
  height: 6rem;
  white-space: pre;
}

.buttons {
  align-items: center;
  display: grid;
  gap: 0 1rem;
  justify-content: center;
}

.section {
  margin-left: 8rem;
  margin-top: -40rem;
}

@media (max-width: 768px) {
  .section {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
  }
}
