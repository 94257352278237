.componentContainer {
  margin-bottom: 2rem;
}

.heroShadowOverlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 45%
  );
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.heroContainer {
  align-items: center;
  display: flex;
}

.heroTitle,
.heroSubtitle {
  color: var(--color-white);
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(90, 90, 90, 1);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  .title {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}

.box {
  border-top: 3px solid var(--color-swatch-4);
  padding: 0 1.25rem;
  .label {
    align-self: center;
    padding-top: 1rem;
  }
}

.statsContainer {
  display: flex;

  .statsList {
    display: flex;
    flex-direction: row;

    li {
      border-right: 1px solid var(--color-grey-20);
      // this needs to change to not flex once the icons are added
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      padding-right: 1rem;

      &:last-of-type {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
      }
    }

    .statNumber {
      color: var(--color-grey-70);
      font-size: 1.5rem;
      font-weight: bold;
    }

    .statLabel {
      color: var(--color-grey-50);
      font-size: 1rem;
    }
  }
}

.progressContainer {
  label {
    text-align: left;
  }
  .editGoal {
    height: 2rem;
    margin-right: 1rem;
  }
}

.tabPanelContainer {
  padding-top: 3rem;
  .teamNameColumn {
    padding: 0;
  }

  > div:not(:first-of-type) {
    > div:nth-child(3) {
      letter-spacing: 1px;
    }
  }
}

.storyContainer {
  .editStory {
    margin: 0.5rem 0 0.5rem auto;
  }
}

@media screen and (max-width: 768px) {
  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .heroComponent {
    min-height: 11rem !important;
  }
}
