.container {
  display: grid;
}

.container > label {
  font-weight: bold;
  font-size: 1rem;
}

@media (min-width: 564px) {
  .container {
    grid-column: 1 / span 4;
  }
}
