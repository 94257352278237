.container {
  h3 {
    font-weight: bold;
    font-size: 1rem;
  }

  .userInfo {
    margin: 1.5rem 0;

    :nth-child(1) {
      font-size: 1rem;
    }

    :nth-child(2) {
      font-size: 1rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .dob,
  .gender,
  .email,
  .phone,
  .general {
    grid-column: 1;
    gap: 1rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
}

//@media (min-width: 564px) {
//  .container {
//    form {
//      grid-template-columns: repeat(4, 1fr);
//    }
//
//    .dob {
//      grid-column: 1 / span 4;
//    }
//
//    .gender {
//      grid-column: 4 / span 1;
//    }
//
//    .email {
//      grid-column: 1 / span 2;
//    }
//
//    .phone {
//      grid-column: 3 / span 2;
//    }
//
//    .general {
//      grid-column: 1 / span 4;
//    }
//
//    .actions {
//      grid-column: 1 / span 4;
//    }
//  }
//}

@media (min-width: 768px) {
  .container article {
    margin: 8vw 8vw 4vw 8vw;
  }
}

@media (min-width: 1280px) {
  .container > aside {
    display: block;
  }
}
