.container {
  .header {
    display: block;
    font-size: medium;
    font-weight: bold;
    padding: 0.5rem 0;
  }
  > p {
    font-size: small;
    margin-left: 1.1rem;
  }
  > input {
    margin-right: 0.3rem;
  }
}
