// Overlay activate link style
.buttonLink {
  background: transparent !important;
  border: none;
  font-size: inherit;
  height: auto;
  padding: 0;
}

// Overlay styles
.container {
  background: var(--color-grey-90);
  color: var(--color-grey-10);
  height: 100%;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 60rem;
  transition: transform 0.25s ease-in-out;
  transform: translateX(100%);
  z-index: 1000;

  .isActive {
    transform: translateX(0);
  }
}

.card {
  background-color: inherit;
  border-radius: 0;
  box-shadow: none;
  color: inherit;

  .cardHeader {
    box-shadow: none;
  }

  .cardHeaderTitle {
    color: var(--color-white);
    font-size: 1.25rem;
    padding: 1.5rem;
  }

  .cardHeaderIcon {
    font-weight: bold;
    padding: 1.5rem;
  }

  .cardContent {
    * {
      color: var(--color-grey-10) !important;
    }
  }
}

// Breakpoint styling
// Tablet
@media screen and (max-width: 1023px) {
  .container {
    width: 75%;
  }
}

// Mobile
@media screen and (max-width: 600px) {
  .container {
    width: 95%;
  }

  .card {
    .cardHeaderTitle {
      font-size: 1rem;
      padding: 1rem;
    }

    .cardHeaderIcon {
      padding: 1rem;
    }
  }
}
