.sidebarContainer {
  height: 100%;
  padding: 1.5rem;
  position: absolute;
  right: 0;

  .sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
  }

  h4 {
    font-weight: bolder;
  }

  section {
    display: grid;
    gap: 1rem;
    margin-top: 5px;
  }

  section > div {
    border-bottom: 1.5px solid var(--color-medium-grey);
    padding: 2%;
    &:last-child {
      border: 0;
    }
  }

  .almostDoneContainer {
    padding-top: 12rem;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    top:0px
  }
}
