.container {
  h2 {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--color-error);
  }

  h6 {
    font-size: 0.9em;
    font-weight: 600;
  }

  .header div:nth-child(1) {
    font-size: 1em;
    font-weight: bold;
  }

  form {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  // TODO: Join Team Pagination Section move to Own component

  .paginationContainer {
    display: flex;
    flex-flow: column wrap;
    .headerContainer {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    .teamContainer {
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-medium-grey);
      margin-top: 3%;
      padding: 1%;
      &:last-child {
        border: 0;
      }
    }
  }
}
