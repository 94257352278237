.container {
  display: grid;
  width: 100%;

  p {
    padding: 1rem 0;
  }

  .yellowAlertSpamTitle {
    color: #777571;
    font-size: bold;
  }

  .yellowAlertSpam {    
    padding:.7rem;    
    color: #777571;    
    border:1px solid #FFD15E;  
    background-color: #FFF9E9;    
    border-radius:.3rem;  
  }

}
