.container {
  /* add arrow shapes for a custom select icon */
  .selectContainer {
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 13px;
      right: 10px;
      width: 0;
      height: 0;
      border: 4px dashed;
      border-color: var(--color-dark-grey) transparent;
      pointer-events: none;
    }

    .error,
    .error:hover {
      border: 1px solid var(--color-error-red);
    }
  }
  
  .selectContainer::before {
    border-bottom-style: solid;
    border-top: 0;
  }

  .selectContainer::after {
    border-bottom: 0;
    border-top-style: solid;
    margin-top: 7px;
  }

  .selectContainer > select {
    appearance: none;
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid var(--color-dark-grey);
    font-size: 16px;
    height: 35px;
    outline: none;
    padding: 6px;
    position: relative;
    width: 100%;
    &:hover {
      border-color: var(--color-swatch-4);
    }
  }
}
