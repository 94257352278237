.heroShadowOverlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 45%,
    rgba(0, 0, 0, 0) 55%,
    rgba(0, 0, 0, 0.7) 100%
  );
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.heroTitle,
.heroSubtitle {
  color: var(--color-white);
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(90, 90, 90, 1);
}

.stripes {
  background-image: repeating-linear-gradient(to right, #d11a46, #d11a46 25%, #18818f 25%, #18818f 50%, #4a2883 50%, #4a2883 75%, #61bb45 75%, #61bb45 100%);
  min-height: 1rem;
  width: 100%;
  margin: 0;
  background-repeat: no-repeat;
}

.eventInfoLinks {
  align-items: flex-start;
  display: flex;

  li {
    align-items: center;
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;

    span {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    .heroIcon {
      background-color: transparent;
      border: 1.5px solid var(--color-white);
      border-radius: 3.1rem;
      height: 3.5rem;
      padding: 1rem;
      position: relative;
      transition: background-color 0.1s linear;
    }

    &:hover {
      .heroIcon {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.buttonGroup {
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 1rem;
    min-width: 20rem;
  }
}

.componentContainer {
  margin-bottom: 4rem;
}

.tabPanelContainer {
  padding-top: 3rem;
  .tableHeader {
    align-self: center;
  }
  .teamNameColumn {
    padding: 0;
  }
}

.eventContent {
  p {
    font-size: 1.25rem;
    margin: 0 2rem 2rem 2rem;
    padding: 0 2rem;
  }
}

.box {
  border-top: 3px solid var(--color-swatch-4);
  padding: 0 1.25rem;
}

.flexColumns {
  flex-direction: column;
}

.levelLeft {
  .flexColumns {
    align-items: flex-start;
  }
}

.hasSolidBg {
  background-color: #1fadc1;
  color: white;
  padding-bottom: 6rem;
  padding-top: 6rem;

  h2,
  h3,
  p,
  a:link,
  a:hover,
  a:visited {
    color: inherit;
  }

  h2,
  h3 {
    font-weight: bold;
  }

  a:link,
  a:visited {
    font-weight: bold;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

.tabPanelContainer {
  padding: 2rem 0;
}

.participantPanel {
  padding: 2rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .heroContainer {
    margin-top: 4rem;
  }

  .heroShadowOverlay {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.2) 80%
    );
  }

  .levelLeft {
    text-align: center;

    .flexColumns {
      align-items: center;
    }
  }

  .socialShare {
    margin-bottom: 0;

    > div {
      justify-content: center;
    }
  }

  .eventContent {
    p {
      font-size: 1.15rem;
      margin: 0 0 1.25rem 0;
      padding: 0;
    }
  }

  .tabPanelContainer {
    padding: 1rem 0;
  }

  .participantPanel {
    padding: 0;
  }
}
