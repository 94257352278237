.accordionContainer {
  border-radius: 0.5rem;
  border: 1px solid var(--color-swatch-4);
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.disabled {
  border-color: var(--color-light-grey);
  background-color: var(--color-grey-10);
  color: var(--color-grey-50) !important;
}

.accordionToggle {
  align-items: center;
  color: var(--color-swatch-4);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  outline: none;
}

.icon {
  margin-top: 8px;
}
