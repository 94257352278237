.container {
  h2 {
    font-size: 1.2em;
    font-weight: bold;
  }

  h6 {
    font-size: 0.9em;
    font-weight: 600;
  }

  .spanred {
    color: red;
  }
  .spanblue {
    color: blue;
  }

  form {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .donationsContainer {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    button {
      height: 36px;
    }
  }
  .optionsImageTowel{
    width: 70%; 
    height: auto;
  }

  .optionsImageHat{
    width: 50%; 
    height: auto;
    margin-left: 35%;
  }

}
