.container {
  .edit {
    padding: 0.5rem 0;
  }

  h3 {
    font-size: medium;
    font-weight: bolder;
  }

  .creditButtonGroup {
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0 1rem 0;
    text-align: center;
  }

  form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 1rem;
  }
}

@media (min-width: 564px) {
  .container {
    form {
      grid-template-columns: repeat(4, 1fr);
    }

    .action,
    .billingInfo,
    .cardNumber {
      grid-column: 1 / span 4;
    }

    .billingName {
      display: flex;
      gap: 0.75rem;
      padding: 1rem 0;
    }

    .expiration {
      grid-column: 1 / span 3;
    }

    .cvv {
      margin-top: 2.6rem;
      grid-column: 4 / span 1;
    }
  }
}
