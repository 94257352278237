.container {
  .inputContainer {
    display: flex;
    position: relative;
  }

  input,
  textarea {
    border-radius: 6px;
    border: 1px solid var(--color-dark-grey);
    flex-grow: 1;
    font-size: 16px;
    justify-content: center;
    outline: none;
    padding: 6px;
    width: 100%;
    &:hover {
      border-color: var(--color-swatch-4);
    }
  }

  .inputLeftPadding {
    padding-left: 1.3rem;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error,
  .error:hover {
    border: 1px solid var(--color-error-red);
  }

  .icon {
    align-self: center;
    cursor: pointer;
    margin: 0 4px;
    margin-right: 8px;
    position: absolute;
    &:hover {
      opacity: 0.8;
      transition: 0.5s;
    }
  }

  .right {
    right: 0;
  }

  > small {
    color: var(--color-error-red);
    font-weight: 800;
    padding: 5px 0;
  }
}
