.otherDonationContainer {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(6, 1fr);
}

.otherInput {
  grid-column: 1 / span 6;
  input {
    text-align: center;
    height: 36px;
  }
}

.otherButton {
  grid-column: 5 / span 2;
}

.donateButtonContainer {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 1rem;
}

.donateButton {
  grid-column: 5 / span 2;
  padding: 6px;
}

.kickstart{
  h2{
    margin: 5% 0 0 0;
  }
  p{
    margin: .75rem 0 0 0;
  }
}
