.container {
  form {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .returningUserText {
    margin-bottom: 0.3rem;
  }

  .loginWithExistingAccount {
    margin: 0;
    padding: 0;
  }

  .checkbox .country .email,
  .errorMessageContainer,
  .firstName,
  .lastName,
  .password,
  .passwordConfirmation .username {
    grid-column: 1;
  }

  .recoverEmail,
  .submitRecoverCredentials {
    grid-column: 1 / span 4;
  }

  .submitRecoverCredentials {
    margin-top: 0.5rem;
  }

  .checkbox {
    font-size: small;
    margin-top: 0.4rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    grid-column: 1;
  }

  .modalActions {
    border-top: 2px solid var(--color-grey-5);
  }

  .loginButton {
    grid-column: 1;
    margin-top: 0.75rem;
  }

  .createAccountButton {
    grid-column: 1;
    margin-top: 0.75rem;
  }
}

@media (min-width: 564px) {
  .container {
    form {
      grid-template-columns: repeat(4, 1fr);
    }

    .actions {
      grid-column: 1 / span 4;
    }

    .firstName {
      grid-column: 1 / span 2;
    }

    .lastName {
      grid-column: 3 / span 2;
    }

    .email {
      grid-column: 1 / span 4;
    }

    .username {
      grid-column: 1 / span 4;
    }

    .password {
      grid-column: 1 / span 4;
    }

    .passwordConfirmation {
      grid-column: 1 / span 4;
    }

    .country {
      grid-column: 1 / span 4;
    }

    .checkbox {
      grid-column: 1 / span 4;
    }

    .errorMessageContainer {
      grid-column: 1 / span 4;
    }
  }
}
