@media (min-width: 564px) {
  .container {
    grid-column: 1 / span 4;
  }

  .waiverCheckbox {
    font-size: 12px;
  }
  
  
}
