.section {
  margin-top: -12rem;
}

.sideColumn {
  > .box {
    background: linear-gradient(var(--color-grey-60), var(--color-grey-90));
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: var(--color-white);

    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    hr {
      height: 1px;
      margin: 2rem 0;
    }
  }
}

.mainColumn {
  margin-top: 11rem;
}

.componentContainer {
  margin-bottom: 2rem;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.editHeroImageBttn {
  position: absolute;
  right: 0;
  top: 3rem;
}

.profileImageContainer {
  margin: -1.25rem -1.25rem 2rem -1.25rem;
  position: relative;

  .editContainer {
    bottom: 1rem;
    left: 1rem;
    position: absolute;
    z-index: 1;
  }

  input[type='file']::-webkit-file-upload-button {
    background-color: var(--color-swatch-4);
    border-radius: 0.5em;
    border: 2px solid var(--color-swatch-4);
    color: var(--color-white);
    outline: none;
    padding: 0.5em;
    transition: 1s;
    margin-right: 1rem;
  }

  input[type='file']::file-selector-button {
    background-color: var(--color-swatch-4);
    border-radius: 0.5em;
    border: 2px solid var(--color-swatch-4);
    color: var(--color-white);
    outline: none;
    padding: 0.5em;
    transition: 1s;
    margin-right: 1rem;
  }

  .editProfile {
    margin-left: auto;
    margin-right: 0.5rem;
  }
}

.eventDetailsContainer {
  > .detailItem {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.25rem;

    span {
      display: block;
    }

    svg {
      margin-right: 1rem;
    }
  }
}

.donorsContainer {
  li {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1.25rem 0;
  }
}

.donationArrayContainer {
  .donationArray {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
  }
}

.progressContainer {
  .editGoal {
    height: 2rem;
    margin-right: 1rem;
  }
}

.storyContainer {
  .editStory {
    margin: 0.5rem 0 0.5rem auto;
  }
}

.thumbnailContainer {
  .thumbnailBttn {
    border: 1px solid transparent;
    margin: 0 auto 0 auto;
    padding: 0;
  }

  .selected {
    border: 3px solid var(--color-swatch-4);
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding-bottom: 0 !important;
  }

  .pageColumns {
    display: flex;
    flex-direction: column-reverse;

    .sideColumn {
      padding: 0;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }

    .mainColumn {
      background: var(--color-white);
      border-radius: 0.5rem 0.5rem 0 0;
      margin-top: 0;
    }
  }

  .profileImageContainer {
    background: var(--color-white);
    margin: -7rem auto 2rem auto;
    max-height: 20rem;
    max-width: 20rem;
    width: 65%;

    height: 20rem;
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .donationArrayContainer {
    .donationArray {
      display: grid;
      gap: 0.75rem;
      grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
  }

  .editHeroImageBttn {
    right: 1rem;
  }

  .heroComponent {
    min-height: 30rem !important;
  }
}
