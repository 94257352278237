.emptyContainer {
  display: flex;
  justify-content: center;
}

.pagination {
  padding: 1rem 0.5rem;
}

.row {
  display: flex;
  align-items: center;
  &.header {
    font-weight: bold;
  }
  &.listItem {
    border-bottom: 1px solid var(--color-medium-grey);
    padding: 1rem 0;
    &:last-child {
      border: none;
    }
  }
}

.cell {
  margin: 0.5rem;
  word-break: break-all;
  .cellHeader {
    color: inherit;
    padding: 0;
    &:hover {
      transition: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .row {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cell {
    width: 100% !important;
    margin: 0.1rem;
  }
}
