.container {
  .edit {
    padding: 0;
    text-decoration: underline;
  }

  .itemHeader {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bold {
    font-weight: bold !important;
  }
}
