.container {
  display: grid;
  position: relative;

  article {
    background-color: #fff;
    padding: 5%;
  }

  article > * + * {
    margin: 5% 0;
  }

  aside {
    background-color: rgb(236, 237, 238);
    box-shadow: -1px 5px 4px 2px #c1c1c1;
    display: none;
    width: 25vw;
  }
}

@media (min-width: 560px) {
  .container {
    background: no-repeat url('../../assets/images/sjmmw-reg-bg.png');
    background-size: 100%;
    grid-template-columns: 1fr 1fr;

    article {
      border-radius: 8px;
      box-shadow: 2px 2px 5px 0 #ddd;
      grid: 1 / 1;
      margin: 5vw 0 4vw 7vw;
      max-width: 600px;
      min-width: 490px;
      padding: 5%;
    }

    aside {
      display: block;
      grid: 2 / 1;
    }
  }
}
