.container {
  h2 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .header div:nth-child(1) {
    font-size: 1em;
    font-weight: bold;
  }

  .prevTeamsContainer {
    display: flex;
    flex-direction: row;
    .existingTeamButton {
      padding: 0;
      align-self: center;
      margin-left: 4px;
    }
  }
}

@media (min-width: 564px) {
  .container {
    .buttons {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      grid-gap: 0.75rem;
    }
  }
}
