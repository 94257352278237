.container {
  h2 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .buttons {
    display: grid;
    grid-gap: 0.75rem;
  }
  .containerLoading {
    position: relative;
  }
  ul{
    list-style: disc;
    margin-left: 0;
    padding-left: 2.25em;
  }
  .alertNeedAdult{    
    padding:.7rem;    
    color: #777571;    
    border:1px solid #FFD15E;  
    background-color: #FFF9E9;    
    border-radius:.3rem;  
  }
}