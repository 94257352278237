.container {
  h4 {
    font-weight: bolder;
  }

  ul {
    display: grid;
    gap: 1rem;
    margin-top: 5px;
  }

  li {
    font-weight: 600;
  }

  .spacing {
    margin: 1vw 0 1vw 0;
  }

  ul > div {
    border-bottom: 1.5px solid var(--color-medium-grey);
    padding: 2%;
    &:last-child {
      border: 0;
    }
  }

  .edit {
    padding: 0;
    text-decoration: underline;
  }
}
