.container {
  display: inline-block;
  position: relative;

  .toolTip {
    background: white;
    border-radius: 4px;
    border: 1px solid var(--color-swatch-4);
    color: var(--color-swatch-4);
    font-size: small;
    font-weight: normal;
    left: 90%;
    line-height: 1;
    padding: 6px;
    position: absolute;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 100;
    &::before {
      content: ' ';
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: -6px;
    }
  }

  .top {
    top: -2rem;
    &::before {
      top: 100%;
      border-top-color: var(--color-swatch-4);
    }
  }
}
