.container {
  --background-color: #ccc;
  --border-radius: 8px;

  progress {
    appearance: none;
    width: 100%;
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    height: 12px;
  }

  label {
    display: block;
    text-align: right;
  }

  /* there is no standard styling for native progress
   * so the following pseudo-elements provide a workaround
   * https://developer.mozilla.org/en-US/docs/Web/CSS/::-moz-progress-bar
   */
  progress::-moz-progress-bar {
    background-color: var(--color-swatch-1);
    border-radius: var(--border-radius);
  }

  progress::-webkit-progress-bar {
    background-color: var(--background-color);
    border-radius: var(--border-radius);
  }

  progress::-webkit-progress-value {
    background-color: var(--color-swatch-1);
    border-radius: var(--border-radius);
  }
}
