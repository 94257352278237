.error {
  background-color: #feeaeb;
  border: 1px solid var(--color-error-red);
  border-radius: 4px;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.boldTextCheckbox{
  font-weight: 700;
}
