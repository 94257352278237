.container {
  display: flex;
  flex-direction: column;

  .labelContainer {
    padding: 0.5rem 0;
    label {
      flex-direction: row;
      font-size: medium;
      font-weight: bold;
    }
  }

  .error {
    color: var(--color-error-red);
  }

  .toolTipAlign {
    margin: 0 4px 4px;
  }

  small {
    color: var(--color-grey-60);
    margin: 0 4px;
    text-align: center;
  }
}
