.topParticipantsList,
.topTeamsList {
  > div {
    justify-content: space-between;

    div:nth-of-type(2) {
      letter-spacing: 1px;
    }
  }
  .action {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    button {
      border: 1px solid var(--color-dark-grey);
      color: var(--color-dark-grey);
      font-weight: bolder;
    }
  }
}

@media screen and (max-width: 1216px) {
  .topParticipantsList,
  .topTeamsList {
    > div {
      flex-wrap: wrap;

      > div {
        width: auto !important;
      }

      > div:first-of-type {
        width: 100% !important;

        button {
          padding: 0;
        }
      }
    }
  }

  .topParticipantsList {
    > div {
      > div {
        button {
          font-size: 1rem;
          padding: 7px 15px;
        }
      }
    }
  }

  .topTeamsList {
    > div {
      > div {
        button {
          font-size: 1rem;
          padding: 5px;
        }
      }
    }
  }
}
