.container {
  align-items: center;
  appearance: none;
  background-color: white;
  border: 1px solid var(--color-swatch-4);
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem 2rem;
  text-align: center;

  .title,
  .subtitle {
    padding: 0 10px;
    width: 100%;
  }

  .title {
    color: var(--color-swatch-4);
    font-size: 1.3em;
    font-weight: bolder;
  }

  .subtitle {
    font-size: 0.9em;
  }
}

.soldOutContainer {
  background-color: var(--color-medium-grey);
  border-color: var(--color-medium-grey);

  .title,
  .subtitle {
    color: #666;
    font-weight: bolder;
  }
}

.container:disabled {
  background-color: var(--color-medium-grey);
  border-color: var(--color-medium-grey);
}

.container:disabled .title,
.container:disabled .subtitle {
  color: #666;
}

.container:not([disabled]):hover {
  background-color: var(--color-swatch-4);
}

.container:not([disabled]):hover .title,
.container:not([disabled]):hover .subtitle {
  color: white;
}

@media (min-width: 560px) {
  .container {
    padding: 1rem 1rem;
  }

  .title {
    font-size: 1.5em;
  }

  .subtitle {
    font-size: 1em;
    margin: 12px 0.1em;
  }
}
