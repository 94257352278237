.container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.street1 {
  grid-column: 1 / span 6;
}

.city {
  grid-column: 1 / span 6;
}

.state {
  grid-column: 1 / span 3;
}

.zip {
  grid-column: 4 / span 3;
}

.country {
  grid-column: 1 / span 6;
}

@media (max-width: 768px) {
  .city {
    grid-column: 1 / span 6;
  }

  .state {
    grid-column: 1 / span 3;
  }

  .zip {
    grid-column: 4 / span 3;
  }

  .email {
    grid-column: 1 / span 6;
  }

  .phone {
    grid-column: 1 / span 6;
  }
}
