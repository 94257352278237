.ageRaceContainer {
  font-size: small;
  font-weight: 700;
  margin: 0.5rem 0;
}

.container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.errorContainer {
  color: var(--color-error);
}

.hasNoDayField {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}
