.container {
  display: table-cell;
}

.container legend {
  font-weight: bold;
  font-size: 1rem;
  display: table;
  margin-bottom: 0;
}
