.heroShadowOverlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 45%
  );
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.heroContainer {
  align-items: center;
  display: flex;
}

.heroTitle,
.heroSubtitle {
  color: var(--color-white);
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(90, 90, 90, 1);
}

.box {
  padding: 0 1.25rem;
}

.componentContainer {
  margin-bottom: 2rem;
}

.participantForm {
  padding-bottom: 42px;
}

.tabPanelContainer {
  padding: 3rem 0;
  .participantPanel {
    form {
      display: grid;
      gap: 1rem;
      justify-content: center;
      padding: 2rem 0;
      .searchParticipantContainer {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  .teamPanel {
    form {
      display: grid;
      gap: 1rem;
      justify-content: center;
      grid-template-columns: 0.3fr;
      padding: 2rem 8rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .tabPanelContainer {
    .teamPanel {
      form {
        grid-template-columns: 1fr;
        padding: 2rem;
      }
    }
  }

  .heroComponent {
    min-height: 10rem !important;
  }
}
