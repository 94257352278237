.button {
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--color-swatch-4);
  color: var(--color-swatch-4);
  cursor: pointer;
  display: flex;
  font-size: medium;
  font-weight: 600;
  justify-content: center;
  outline: none;
  padding: 14px;
}

.fullWidth {
  max-width: none;
  width: 100%;
}

.xs {
  padding: 6px;
}

.primaryButton {
  background-color: var(--color-swatch-4);
  color: white;
}

.secondaryButton {
  background-color: transparent;
  border-color: var(--color-medium-grey);
}

.donateButton {
  background-color: var(--color-swatch-1);
  border-color: var(--color-swatch-1);
  color: var(--color-white);
}

.textVariant {
  background-color: transparent;
  border-color: transparent;
  padding: 8px;
  &:hover {
    font-weight: bold;
    opacity: 1;
    transition: 0.5s;
  }
}

.disabledButton {
  background-color: var(--color-medium-grey);
  border-color: var(--color-medium-grey);
}

.loader {
  padding: 0 !important;
}

.bottom {
  flex-direction: column-reverse;
}

.left {
  flex-direction: row;
}

.right {
  flex-direction: row-reverse;
}

.top {
  flex-direction: column;
}

.iconStyles {
  margin-left: 0.2rem;
}
