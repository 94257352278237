.hoursContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

@media (min-width: 564px) {
  .multiTimeContainer {
    grid-column: 1 / span 4;
  }
}
