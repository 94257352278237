.section {
  margin-top: -12rem;
}

.sideColumn {
  > .box {
    background: linear-gradient(var(--color-grey-60), var(--color-grey-90));
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: var(--color-white);

    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    hr {
      height: 1px;
      margin: 2rem 0;
    }
  }
}

.mainColumn {
  margin-top: 11rem;
  .teamRoster {
    border-top: 4px solid var(--color-swatch-4);
    padding: 1rem 5rem;
    .label {
      align-self: center;
    }
    .action {
      align-items: center;
      display: flex;
      justify-content: center;
      button {
        border: 1px solid var(--color-dark-grey);
        color: var(--color-dark-grey);
        font-weight: bolder;
      }
    }
  }
}

.componentContainer {
  margin-bottom: 2rem;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.teamStatsContainer {
  display: flex;

  .teamStats {
    display: flex;
    flex-direction: row;

    li {
      border-right: 1px solid var(--color-grey-20);
      // this needs to change to not flex once the icons are added
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      padding-right: 1rem;

      &:last-of-type {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
      }
    }

    .statNumber {
      color: var(--color-grey-70);
      font-size: 1.5rem;
      font-weight: bold;
    }

    .statLabel {
      color: var(--color-grey-50);
      font-size: 1rem;
    }
  }
}

.profileImageContainer {
  margin: -1.25rem -1.25rem 2rem -1.25rem;
  position: relative;

  .editContainer {
    bottom: 1rem;
    left: 1rem;
    position: absolute;
    z-index: 1;
  }

  input[type='file']::-webkit-file-upload-button {
    background-color: var(--color-swatch-4);
    border-radius: 0.5em;
    border: 2px solid var(--color-swatch-4);
    color: var(--color-white);
    outline: none;
    padding: 0.5em;
    transition: 1s;
    margin-right: 1rem;
  }

  input[type='file']::file-selector-button {
    background-color: var(--color-swatch-4);
    border-radius: 0.5em;
    border: 2px solid var(--color-swatch-4);
    color: var(--color-white);
    outline: none;
    padding: 0.5em;
    transition: 1s;
    margin-right: 1rem;
  }

  .editProfile {
    margin-left: auto;
    margin-right: 0.5rem;
  }
}

.eventDetailsContainer {
  > .detailItem {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.25rem;

    svg {
      margin-right: 1rem;
    }
  }
}

.progressContainer {
  .editGoal {
    height: 2rem;
    margin-right: 1rem;
  }
}

.rosterListContainer {
  > div > div:nth-of-type(2) {
    letter-spacing: 1px;
  }
}

.storyContainer {
  .editStory {
    margin: 0.5rem 0 0.5rem auto;
  }
}

.ctaButtons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4rem;
  button {
    width: 300px;
    margin-left: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .ctaButtons {
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: 4rem;
    button {
      width: 300px;
      margin-top: 1rem;
      margin-left: 0;
    }
  }

  .section {
    padding-bottom: 0 !important;
  }

  .pageColumns {
    display: flex;
    flex-direction: column-reverse;

    .sideColumn {
      padding: 0;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }

    .mainColumn {
      background: var(--color-white);
      border-radius: 0.5rem 0.5rem 0 0;
      margin-top: 0;
      .teamRoster {
        padding: 1rem;
        .rosterListContainer {
          > div {
            > div {
              width: auto !important;
            }
            > div:first-of-type {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .profileImageContainer {
    background: var(--color-white);
    margin: -7rem auto 2rem auto;
    max-height: 20rem;
    max-width: 20rem;
    width: 65%;

    height: 20rem;
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
