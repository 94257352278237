.container {
  h2 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 0.75rem;
  }

  .accordion {
    display: grid;
    gap: 0.75rem;
  }

  ul{
    list-style: disc;
    margin-left: 0;
    padding-left: 2.25em;
  }
}

@media (min-width: 564px) {
  .container {
    .buttons {
      grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
  }
}
