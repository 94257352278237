html {
  font-size: $base-font-size-mobile;
}

// Desktop screens
@include from($tablet) {
  html {
    font-size: $base-font-size-mobile;
  }
}

// Large screens
@include from($fullhd) {
  html {
    font-size: $base-font-size-mobile;
  }
}

// Extend link to have transitions
a {
  transition: color 0.1s ease-in-out;
}

// Horizontal rule
.line {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  clear: both;
  margin: $block-spacing 0;
  position: relative;
  width: 100%;

  &.double {
    margin: calc(#{$block-spacing} * 2) 0;
  }
}

// Sticky footer setup
html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  > nav.navbar,
  > footer.footer {
    flex-shrink: 0;
  }

  > main {
    flex: 1 0 auto;
    position: relative;
  }
}

// Pre-formatted text settings
.formatted-text {
  white-space: pre-line;
}

// Divider extension
.is-divider.is-small {
  margin: 0.5rem 0;
}

// Marginless extender
.is-marginless {
  margin: 0;
}

// Paddingless extender
.is-paddingless {
  padding: 0;
}

// Header styles
// User Items
.user-navitem {
  border-left: 1px solid $grey-lightest;
}

.user-icon {
  background: #333;
  border-radius: 50%;
  color: $white;
  height: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  width: 1.7rem;
}
