.container {
  .attendanceHeader {
    font-size: 1.2em;
    font-weight: bold;
  }

  .buttons {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 564px) {
  .container {
    .buttons {
      grid-template-columns: 1fr 1fr;
    }
  }
}
