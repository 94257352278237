.container {
  background-color: var(--color-light-grey);
  border-radius: 4px;
  border: 1px solid var(--color-error-red);
  display: flex;
  padding: 4px;
  width: 100%;

  > p {
    font-size: medium;
    font-weight: 600;
    color: var(--color-error-red);
  }
}
