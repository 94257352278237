.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 0;

  .title {
    color: var(--color-swatch-1);
  }
}

.formContainer {
  margin-top: 1rem;

  form {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
  }

  .caption {
    grid-column: 1 / span 4;
  }

  .firstName {
    grid-column: 1 / span 2;
  }

  .lastName {
    grid-column: 3 / span 2;
  }

  .question {
    grid-column: 1 / span 4;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 4;
    margin-top: 1rem;
  }
}

@media (max-width: 770px) {
  .container {
    padding: 2rem 1rem;
  }

  .formContainer {
    form {
      grid-template-columns: 1fr;
    }

    .firstName,
    .lastName {
      grid-column: 1 / span 4;
    }

    .question {
      grid-column: 1 / span 4;
    }
  }
}
