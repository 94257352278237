.container {
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0 1rem 0;
  }

  label {
    display: grid;
    grid-template-columns: 5% auto;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0 1rem;
  }

  label:nth-last-of-type(1) {
    margin-top: 1rem;
  }
}

.constituentName {
  font-weight: bold;
  grid-row: 2;
  grid-column: 2;
}
